import React from 'react'
import styles from '../Main Banner/MainBanner.module.css'

const MobileBanner = ({ banner, heading, para, additionalClass, height, page }) => {

  if (['residential', 'commercial', 'sustainability', 'home-carousel2'].includes(page)){
    return (
      <>
        <div style={{height: `${height}`}} className={`${styles.mainBanner} ${styles.mobileBannerAdditional}`}>
          <img className={styles.image} src={banner} alt={'banner'} />
          <div className={styles.mainBannerContent}>
            <h1 className={styles.heading}>{heading}</h1>
          </div>
          {para && <p className={`${styles.text} 
            ${page === 'residential' && styles.residentialText} 
            ${page === 'commercial' && styles.commercialText}
            ${page === 'sustainability' && styles.sustainabilityText}
            ${page === 'home-carousel2' && styles.homeCarouselText}
          `}>{para}</p>}
        </div>
      </>
    )
  }
  else {
    return (
      <>
        <div style={{height: `${height}`}} className={`${styles.mainBanner} ${additionalClass && styles.mobileBannerAdditional}`}>
          <img className={styles.image} src={banner} alt={'banner'} />
          <div className={styles.mainBannerContent}>
            <h1 className={styles.heading}>{heading}</h1>
            {para && <p className={styles.text}>{para}</p>}
          </div>
        </div>
      </>
    )
  }
}

export default MobileBanner