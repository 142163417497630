import React from 'react';
import wing1 from '../../assets/wingBack.png'
import wing2 from '../../assets/wingFront.png'
import styles from './Wings.module.css'
// import styles from './HeaderWing.module.css'

export default function Wings({ forComponent, showWings }) {

    return (
      <>
    <div className={`${styles.wing} ${forComponent === 'tote' ? styles.wing_tote : (forComponent === 'bin' ? styles.wing_bin : null)}`}>

        <img className={`${styles.wingBack} ${forComponent === 'tote' ? styles.singleWing_tote : (forComponent === 'bin' ? styles.singleWing_bin : null)} ${showWings && styles.wingBackHovered}`} src={wing1} alt='wing-1' />
        <img className={`${styles.wingFront} ${forComponent === 'tote' ? styles.singleWing_tote : (forComponent === 'bin' ? styles.singleWing_bin : null)} ${showWings && styles.wingFrontHovered}`} src={wing2} alt='wing-1' />

    </div>
    </>
  )
}
