// HeaderWing.js
import React from 'react';
import styles from './HeaderWing.module.css'
import wing1 from '../../assets/wingBack.png'
import wing2 from '../../assets/wingFront.png'


const HeaderWing = ({ showWings }) => {

  return <div className={`${styles.wing} `}>

  <img className={`${styles.wingBack}  ${showWings && styles.wingBackHovered}`} src={wing1} alt='wing-1' />
  <img className={`${styles.wingFront}  ${showWings && styles.wingFrontHovered}`} src={wing2} alt='wing-1' />

</div>;
};

export default HeaderWing;
