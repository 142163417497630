import React, { useState } from 'react'
import styles from './Header.module.css'
import logo from '../../assets/newlogo.png'
import { NavLink, useNavigate } from 'react-router-dom'
import HeaderWing from '../Wings/HeaderWing'

const Header = () => {
    const[showWings, setShowWings] = useState(false);
    const[link, setLink] = useState('')
    const navigate = useNavigate()

    const handleMouseEnter =(link_name)=> {
        if (link !== link_name) setLink(link_name);
        if(link !== link_name) {
            setTimeout(() => {
                setShowWings(true)
            }, 100)
            setTimeout(() => {
                setShowWings(false)
            }, 800)
        }
        else {
            setShowWings(true)
            setTimeout(() => {
                setShowWings(false)
            }, 700)
        }
    }
    

    const handleMouseLeave = () => {
        setLink('')
        setShowWings(false);
    }

    const handleLinkClick = (link_name) => {
        setLink(link_name);
        setShowWings(true);
      };

    return (
        <div className={styles.header}> 
            <div className={styles.wrapper}>
                <div className={styles.container} > 
                    <img src={logo} alt='headerlogo' onClick={() => navigate('/')} />
                </div>

                <ul>
                    <li className={styles.link} onMouseEnter={() => handleMouseEnter('RESIDENTIAL SERVICES')} onMouseLeave={() => handleMouseLeave()}>
                        <NavLink to={'/residential-services'} onClick={() => handleLinkClick('RESIDENTIAL SERVICES')} className={({ isActive }) => isActive ? `${styles.active}` : `${styles.notActive}`}>
                            RESIDENTIAL SERVICES
                            </NavLink>
                        {link === 'RESIDENTIAL SERVICES' && <HeaderWing showWings={showWings}/>}
                        </li>

                        <li className={styles.link} onMouseEnter={() => handleMouseEnter('COMMERCIAL SERVICES')} onMouseLeave={() => handleMouseLeave()}>
                        <NavLink to={'/commercial-services'} className={({ isActive }) => isActive ? `${styles.active}` : `${styles.notActive}`}>COMMERCIAL SERVICES</NavLink>
                        {link === 'COMMERCIAL SERVICES' && <HeaderWing showWings={showWings}/>}

                        </li>

                        <li className={styles.link} onMouseEnter={() => handleMouseEnter('SUSTAINABILITY')} onMouseLeave={() => handleMouseLeave()}>
                        <NavLink to={'/sustainability'} className={({ isActive }) => isActive ? `${styles.active}` : `${styles.notActive}`}>SUSTAINABILITY</NavLink>
                        {link === 'SUSTAINABILITY' && <HeaderWing showWings={showWings}/>}

                        </li>

                        <li className={styles.link} onMouseEnter={() => handleMouseEnter('CONTACT')} onMouseLeave={() => handleMouseLeave()}>
                        <NavLink to={'/contact'} className={({ isActive }) => isActive ? `${styles.active}` : `${styles.notActive}`}>CONTACT US</NavLink>
                        {link === 'CONTACT' && <HeaderWing showWings={showWings}/>}

                        </li>
                </ul>
            </div>

        </div>
    )
}

export default Header