import React, { useEffect, useState } from "react";
import styles from "./FrontLoadBins.module.css";
import container1 from "../../assets/FrontLoadBins/FLB01.png";
import container2 from "../../assets/FrontLoadBins/FLB02.png";
import container3 from "../../assets/FrontLoadBins/FLB003.png";
import container4 from "../../assets/FrontLoadBins/FLB04.png";
import container5 from "../../assets/FrontLoadBins/FLB05.png";
import container6 from "../../assets/FrontLoadBins/FLB06.png";

import W80o from "../../assets/new-dim/80W.png";
import H36o from "../../assets/new-dim/36H.png";
import H48o from "../../assets/new-dim/48H.png";
import H60o from "../../assets/new-dim/60H.png";
import H80o from "../../assets/new-dim/80H.png";
import H54o from "../../assets/new-dim/54H.png";
import D40o from "../../assets/new-dim/40D.png";
import D54o from "../../assets/new-dim/54D.png";
import D80o from "../../assets/new-dim/80D.png";
import D76o from "../../assets/new-dim/76D.png";
import D66o from "../../assets/new-dim/66D.png";

import Wings from "./HeaderWing";
import Wings2Yards from "./../Wings/Wings2Yards";
import HorizontalCard from "../Horizontal Card/HorizontalCard";
import truck from "../../assets/images with logo/TruckFrontLoadBin.jpg";

const FrontLoadBins = () => {
  let hc_head1 = "FRONT-LOAD BINS";
  let hc_size1 = [
    { text: "2 Yard", bold: true },
    { text: "4 Yard" },
    { text: "6 Yard Slant" },
    { text: "6 Yard Tall" },
    { text: "8 Yard Slant" },
    { text: "8 Yard Tall" },
  ];

  const [FL_Bins, setFL_Bins] = useState("2yard");
  const [showWings, setShowWings] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseAction = (FL_BinsName) => {
    if (FL_Bins !== FL_BinsName) setFL_Bins(FL_BinsName);
    if (FL_Bins !== FL_BinsName) {
      setTimeout(() => {
        setShowWings(true);
      }, 100);
      setTimeout(() => {
        setShowWings(false);
      }, 800);
    } else {
      setShowWings(true);
      setTimeout(() => {
        setShowWings(false);
      }, 700);
    }
  };

  const handleMouseLeave = () => {
    setFL_Bins("2yard");
    setShowWings(false);
  };

  return (
    <div>
      <HorizontalCard
        isLeft={false}
        heading={hc_head1}
        rightImageText={hc_size1}
        img={truck}
      />
      <div className={styles.FL_Binss}>
        <div>
          <div
            className={styles.imageContainer}
            onMouseEnter={() => handleMouseAction("2yard")}
            onClick={() => handleMouseAction("2yard")}
            onMouseLeave={() => setShowWings(false)}
            style={{ paddingLeft: windowWidth < 1060 ? "18px" : "0px" }}
          >
            <img className={styles.image1} src={container1} alt="2 Yard" />
            {FL_Bins === "2yard" && (
              <Wings2Yards
                forComponent={"ForontLoadBins"}
                showWings={showWings}
              />
            )}

            <img className={styles.verticalLine_2yard} src={H36o} alt="4 ft" />
            <img
              className={styles.horizontalLine_2yard}
              src={W80o}
              alt="12 ft"
            />
            <img className={styles.depth_2yard} src={D40o} alt="4 ft" />
          </div>
          <div className={`${styles.text} ${styles.bold}`}>2 Yard</div>
        </div>
        <div>
          <div
            className={styles.imageContainer}
            onMouseEnter={() => handleMouseAction("4yard")}
            onClick={() => handleMouseAction("4yard")}
            onMouseLeave={handleMouseLeave}
          >
            <img className={styles.image2} src={container2} alt="4 Yard" />
            {FL_Bins === "4yard" && (
              <Wings style={{left:"2px"}} forComponent={"ForontLoadBins"} showWings={showWings} />
            )}
            <img className={styles.verticalLine_4yard} src={H48o} alt="4 ft" />
            <img
              className={styles.horizontalLine_4yard}
              src={W80o}
              alt="22 ft"
            />
            <img className={styles.depth_4yard} src={D54o} alt="4 ft" />
          </div>
          <div className={styles.text}>4 Yard</div>
        </div>

        <div>
          <div
            className={styles.imageContainer}
            onMouseEnter={() => handleMouseAction("6yardSlant")}
            onClick={() => handleMouseAction("6yardSlant")}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className={styles.image3}
              src={container3}
              alt="6 yard Slant"
            />
            {FL_Bins === "6yardSlant" && (
              <Wings forComponent={"ForontLoadBins"} showWings={showWings} />
            )}
            <img
              className={styles.verticalLine_6yardSlant}
              src={H36o}
              alt="8 ft"
            />
            <img
              className={styles.horizontalLine_6yardSlant}
              src={W80o}
              alt="22 ft"
            />
            <img className={styles.depth_6yardSlant} src={D76o} alt="22 ft" />
          </div>
          <div className={styles.text}>6 Yard Slant</div>
        </div>
        <div>
          <div
            className={styles.imageContainer}
            onMouseEnter={() => handleMouseAction("6yardTall")}
            onClick={() => handleMouseAction("6yardTall")}
            onMouseLeave={handleMouseLeave}
            style={{ paddingLeft: windowWidth < 1060 ? "10px" : "0px" }}
          >
            <img className={styles.image4} src={container4} alt="6 yard Tall" />
            {FL_Bins === "6yardTall" && (
              <Wings forComponent={"ForontLoadBins"} showWings={showWings} />
            )}
            <img
              className={styles.verticalLine_6yardTall}
              src={H60o}
              alt="8 ft"
            />
            <img
              className={styles.horizontalLine_6yardTall}
              src={W80o}
              alt="22 ft"
            />
            <img className={styles.depth_6yardTall} src={D66o} alt="22 ft" />
          </div>
          <div className={styles.text}>6 Yard Tall</div>
        </div>
        <div>
          <div
            className={styles.imageContainer}
            onMouseEnter={() => handleMouseAction("8yardSlant")}
            onClick={() => handleMouseAction("8yardSlant")}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className={styles.image5}
              src={container5}
              alt="8 yard Slant"
            />
            {FL_Bins === "8yardSlant" && (
              <Wings forComponent={"ForontLoadBins"} showWings={showWings} />
            )}
            <img
              className={styles.verticalLine_8yardSlant}
              src={H54o}
              alt="8 ft"
            />
            <img
              className={styles.horizontalLine_8yardSlant}
              src={W80o}
              alt="22 ft"
            />
            <img className={styles.depth_8yardSlant} src={D80o} alt="22 ft" />
          </div>
          <div className={styles.text}>8 Yard Slant</div>
        </div>
        <div>
          <div
            className={styles.imageContainer}
            onMouseEnter={() => handleMouseAction("8yardTall")}
            onClick={() => handleMouseAction("8yardTall")}
            onMouseLeave={handleMouseLeave}
          >
            <img className={styles.image6} src={container6} alt="8 Yard Tall" />
            {FL_Bins === "8yardTall" && (
              <Wings forComponent={"ForontLoadBins"} showWings={showWings} />
            )}
            <img
              className={styles.verticalLine_8yardTall}
              src={H80o} //H76
              alt="8 ft"
            />
            <img
              className={styles.horizontalLine_8yardTall}
              src={W80o} // L80
              alt="22 ft"
            />
            <img
              className={styles.depth_8yardTall}
              src={D66o} // D71
              alt="22 ft"
            />
          </div>
          <div className={`${styles.text} yard-tall `}>8 Yard Tall</div>
        </div>
      </div>
    </div>
  );
};

export default FrontLoadBins;
