import React from "react";
import styles from "./Privacy.module.css";

const Privacy = () => {
  return (
    <div className={styles.terms_privacy}>
      <h1 className={styles.priv_heading}>PRIVACY POLICY</h1>
      <p className={styles.para}>
        <i>DRAGONFLY ENVIRONMENTAL LOGISTICS</i>, including its subsidiaries and
        affiliates (hereinafter <i>“DRAGONFLY ENVIRONMENTAL LOGISTICS“</i>)
        understands the importance of protecting your personal information (the
        “Personal Information“). We have adopted this privacy policy (the
        “Policy“) to explain how we collect, store, use and share the Personal
        Information that you provide to us or that we collect when you use the
        websites, applications, games, features, or any other function
        controlled by <i>DRAGONFLY ENVIRONMENTAL LOGISTICS</i>, as described in
        the Policy (the “Services“).
      </p>
      <p className={styles.para}>
        IF YOU DO NOT AGREE TO THE POLICY, YOU MUST NOT USE THE{" "}
        <i>DRAGONFLY ENVIRONMENTAL LOGISTICS SERVICES</i>.
      </p>

      <h3 className={styles.heading}>
        What Personal Information is collected by{" "}
        <i>DRAGONFLY ENVIRONMENTAL LOGISTICS</i>?
      </h3>
      <p className={styles.para}>
        <ul className={styles.list}>
          <li> Identification and contact data (e.g. internal identifier);</li>
          <li> Connection data (e.g. IP address);</li>
          <li> Location data (e.g. country of origin).</li>
        </ul>
      </p>

      <h3 className={styles.heading}>
        For what purposes is the Personal Information used?
      </h3>
      <p className={styles.para}>
        <ul className={styles.list}>
          <li> Customize and improve the Services;</li>
          <li> Analyze and provide statistics of the use of the Services;</li>
          <li> Manage clearly unlawful conduct and misuse of the Services;</li>
          <li> Send newsletters and promotions;</li>
          <li> Treat job applications.</li>
        </ul>
      </p>

      <h3 className={styles.heading}>
        Is the Personal Information shared with third parties?
      </h3>
      <p className={styles.para}>
        We may engage subcontractors to collect and process your Personal
        Information on our behalf and for our benefit, in order to provide
        certain services such as sending e-mails and processing payments. These
        subcontractors may be located outside the European Economic Area (EEA).
        When the Personal Information is shared with our subcontractors, we take
        reasonable contractual measures to adequately protect the Personal
        Information.
      </p>

      <h3 className={styles.heading}>
        How can you contact <i>DRAGONFLY ENVIRONMENTAL LOGISTICS </i>or request
        the deletion of your Personal Information?
      </h3>
      <p className={styles.para}>
        <ul className={styles.list}>
          <li>Attn: Legal Department</li>
          <li>
            By email at:{" "}
            <span style={{ wordSpacing: "-5px" }}>
              customerservice @ dragonflyenvironmental .com
            </span>
          </li>
          <li>By mail at: 370 Ontario Street North, Milton, ON - L9T 2V1</li>
        </ul>
      </p>
    </div>
  );
};

export default Privacy;