import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { Helmet } from 'react-helmet'

const ErrorPage = () => {
  return (
    <>
      <Helmet>
        <title>Dragonfly Environmental Logistics - Page Not Found</title>
      </Helmet>
      <Header/>
      <div style={{height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h1 style={{fontWeight: 'lighter'}}>404 not found</h1>
      </div>
      <Footer/>
    </>
  )
}

export default ErrorPage