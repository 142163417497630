import React from "react";
import styles from "./YellowBanner.module.css";

export default function YellowBanner({ havePara, heading, para }) {
  return (
    <div className={styles.bannerContent}>
      <h1 className={styles.heading}>
        {heading}
      </h1>
      {havePara && <p className={styles.para}>
        {para}
      </p>}
    </div>
  );
}

