import React from 'react'
import MainBanner from '../../components/Main Banner/MainBanner'
import mediumBanner from '../../assets/banner images/medium/compactor.webp'
import largeBanner from '../../assets/banner images/large/compactor.webp'
import mobileBanner from '../../assets/banner images/mobile banner/compactor.webp'
import YellowBanner from '../../components/Yellow Banner/YellowBanner';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import HorizontalCard from '../../components/Horizontal Card/HorizontalCard';
import truck5 from '../../assets/images with logo/image5.jpg'
import truck6 from '../../assets/images with logo/image4.jpg'
import { Helmet } from "react-helmet";
import Services from '../../components/Services/Services';
import Tote from '../../components/Tote/Tote';
import FrontLoadBins from '../../components/FrontLoadBins/FrontLoadBins';


const CommercialServices = () => {

  let mb_head='Let Dragonfly Commercial Waste Services set your operations team free';
  let mb_para= 'To focus on propelling your business forward.';
  let yb_head= 'At Dragonfly Environmental Logistics we offer end-to-end services, keeping your business agile and adaptable to deal with the decisions that matter most.';
  let hc_head5 = 'ROLL-OFF SERVICES';
  let hc_head6 = 'COMPACTORS'
  let hc_para6=`Self-Contained, Stationary, Vertical Bailer, Apartment & High-Rise, Pre-Crusher and Compaction Containers available.`
  let hc_size5 = [{text: '14 Yard', bold: true}, {text: '20 Yard'}, {text: '40 Yard'}]

  return (
    <>
      <Helmet>
        <title>Dragonfly Environmental Logistics - Commercial Services</title>
      </Helmet>
      <Header/>
      <MainBanner largeBanner={largeBanner} mediumBanner={mediumBanner} mobileBanner={mobileBanner} havePara={true} heading={mb_head} para={mb_para} additionalClass={true} page={'commercial'}/>
      <Services page={'commercial'} />
      <YellowBanner havePara={false} heading={yb_head}/>
      <HorizontalCard isLeft={false} heading={hc_head5} rightImageText={hc_size5} img={truck5}/>
      <Tote/>
      <FrontLoadBins/>
      <HorizontalCard isLeft={false} heading={hc_head6} text={hc_para6} img={truck6}/>
      <Footer/>
    </>
  )
}

export default CommercialServices