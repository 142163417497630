import './App.css';
import './fonts.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/home/Home';
import ResidentialServices from './pages/residential-services';
import CommercialServices from './pages/commercial-services';
import Sustainability from './pages/sustainability';
import Contact from './pages/contact'
import ErrorPage from './pages/error-page';
import TermsOfUses from './pages/TermsOfUses/TermsOfUses';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/residential-services' element={<ResidentialServices/>} />
          <Route path='/commercial-services' element={<CommercialServices/>} />
          <Route path='/sustainability' element={<Sustainability/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='terms' element={<TermsOfUses/>}/>
          <Route path='privacy' element={<PrivacyPolicy/>}/>
          <Route path='*' element={<ErrorPage/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
