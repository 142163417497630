import React from 'react'
import Terms from '../../components/Terms/Terms'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

const TermsOfUses = () => {
  return (
    <div>
        <Header/>
        <Terms/>
        <Footer/>
    </div>
  )
}

export default TermsOfUses