import React, { useState } from 'react'
import styles from './Bin.module.css'
import Wings from '../Wings/Wings'
import binImage from "../../assets/bin1.png";

const Bin = () => {

    const [bin, setBin] = useState('32gallon')
    const [showWings, setShowWings] = useState('')

    const handleMouseAction = (binName) => {
        if (bin !== binName) setBin(binName);
        if(bin !== binName) {
            setTimeout(() => {
                setShowWings(true)
            }, 100)
            setTimeout(() => {
                setShowWings(false)
            }, 800)
        }
        else {
            setShowWings(true)
            setTimeout(() => {
                setShowWings(false)
            }, 700)
        }
    }

    const handleMouseLeave = () => {
        setBin('32gallon')
        setShowWings(false)
    }

  return (
    <div>
        <div className={styles.bins}>
            <div>
                <div className={styles.imageContainer} onMouseEnter={() => handleMouseAction('32gallon')} onClick={() => handleMouseAction('32gallon')} onMouseLeave={() => setShowWings(false)}>
                    <img className={styles.image1} src={binImage} alt='32 Gallon' />
                    {bin === '32gallon' && <Wings forComponent={'bin'} showWings={showWings} />}
                </div>
                <div className={`${styles.text} ${styles.bold}`}>32 Gallon</div>
                
            </div>
            <div>
                <div className={styles.imageContainer} onMouseEnter={() => handleMouseAction('96gallon')} onClick={() => handleMouseAction('96gallon')} onMouseLeave={handleMouseLeave}>
                    <img className={styles.image2} src={binImage} alt='96 Gallon' />
                    {bin === '96gallon' && <Wings forComponent={'bin'} showWings={showWings} />}
                </div>
                <div className={styles.text}>96 Gallon</div>
            </div>
        </div>
    </div>
  )
}

export default Bin