import React, { useState } from "react";
import styles from "./HorizontalContact.module.css";
import Wings from "../Wings/Wings";
import phoneIcon from "../../assets/icons/phone.jpg"
import emailIcon from "../../assets/icons/email.jpg"

const HorizontalContact = ({ heading1, heading2, desc1, desc2, bigimg }) => {

  const [showWings, setShowWings] = useState(false)

  const handleMouseAction = () => {
    setShowWings(true)
    setTimeout(() => {
      setShowWings(false)
    }, 700)
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.desc}>
        <h2>{heading1}</h2>
        <p className={styles.space}>{desc1}</p>
        
        <h2>{heading2}</h2>
        <p className={styles.space}>{desc2}</p>
        <p className={styles.nospace}>
          <img src={phoneIcon} alt="phone icon" width={50} />888-DFLY-ENV (888-335-9368)<br/>
          <span style={{wordSpacing: '-5px', fontSize: 'inherit'}}>
            <a 
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }} 
              href="mailto:customerservice@dragonflyenvironmental.com"
            >
              <img src={emailIcon} alt="email icon" width={50} />
              customerservice @ dragonflyenvironmental.com
            </a>
          </span>
        </p>
      </div>
      
      <div className={styles.mapimg}>
      <img src={bigimg} alt="map" className={styles.map} onMouseEnter={handleMouseAction} onClick={handleMouseAction} style={{cursor: 'pointer'}} />
        <div className={styles.wingContact}>
          <Wings showWings={showWings}/>
        </div>
      </div>
    </div>
  );
};

export default HorizontalContact;
