import React, { useState } from 'react'
import styles from './Tote.module.css'
import container1 from "../../assets/container1.png";
import container2 from "../../assets/container2.png";
import container3 from "../../assets/container3.png";

import ft12 from "../../assets/toteDim/12L.png";
import ft22 from "../../assets/toteDim/22L_2.png";
import ft4 from "../../assets/toteDim/4H_3.png";
import ft8 from "../../assets/toteDim/8H_3.png";
import D8 from "../../assets/toteDim/8W.png";

import Wings from '../Wings/Wings';

const Tote = () => {

    const [tote, setTote] = useState('14yard')
    const [showWings, setShowWings] = useState(false)

    const handleMouseAction = (toteName) => {
        if (tote !== toteName) setTote(toteName);
        if(tote !== toteName) {
            setTimeout(() => {
                setShowWings(true)
            }, 100)
            setTimeout(() => {
                setShowWings(false)
            }, 800)
        }
        else {
            setShowWings(true)
            setTimeout(() => {
                setShowWings(false)
            }, 700)
        }
    }

    const handleMouseLeave = () => {
        setTote('14yard')
        setShowWings(false)
    }

  return (
    <div>
        <div className={styles.totes}>
            <div>
                <div className={styles.imageContainer} onMouseEnter={() => handleMouseAction('14yard')} onClick={() => handleMouseAction('14yard')} onMouseLeave={() => setShowWings(false)}>
                    <img className={styles.image} src={container1} alt='14 Yard' />
                    {tote === '14yard' && <Wings forComponent={'tote'} showWings={showWings} />}
                    <img className={styles.verticalLine_line} src={ft4} alt="4 ft" />
                    <img className={styles.horizontalLine_14yard} src={ft12} alt="12 ft" />
                    <img className={styles.depth_yard} src={D8} alt="14 ft" />
                </div>
                <div className={`${styles.text} ${styles.bold}`}>14 Yard</div>
            </div>
            <div>
                <div className={styles.imageContainer} onMouseEnter={() => handleMouseAction('20yard')} onClick={() => handleMouseAction('20yard')} onMouseLeave={handleMouseLeave}>
                    <img className={styles.image} src={container2} alt='20 Yard' />
                    {tote === '20yard' && <Wings forComponent={'tote'} showWings={showWings} />}
                    <img className={styles.verticalLine_line} src={ft4} alt="4 ft" />
                    <img className={styles.horizontalLine_20yard} src={ft22} alt="22 ft" />
                    <img className={styles.depth_yard} src={D8} alt="14 ft" />

                </div>
                <div className={styles.text}>20 Yard</div>
            </div>
            <div>
                <div className={styles.imageContainer} onMouseEnter={() => handleMouseAction('40yard')} onClick={() => handleMouseAction('40yard')} onMouseLeave={handleMouseLeave}>
                    <img className={styles.image3} src={container2} alt='40 Yard' />
                    {tote === '40yard' && <Wings forComponent={'tote'} showWings={showWings} />}
                    <img className={styles.image3_verticalLine_line} src={ft8} alt="8 ft" />
                    <img className={styles.horizontalLine_20yard} src={ft22} alt="22 ft" />
                    <img className={styles.image3_depth_yard} src={D8} alt="14 ft" />
                </div>
                <div className={styles.text}>40 Yard</div>
            </div>

        {/*
            <div>
                <div className={styles.imageContainer} onMouseEnter={() => handleMouseAction('40yard')} onClick={() => handleMouseAction('40yard')} onMouseLeave={handleMouseLeave}>
                    <img className={styles.image} src={container3} alt='40 Yard' />
                    {tote === '40yard' && <Wings forComponent={'tote'} showWings={showWings} />}
                    <img className={styles.verticalLine_line} src={ft8} alt="8 ft" />
                    <img className={styles.horizontalLine_40yard} src={ft22} alt="22 ft" />
                    <img className={styles.depth_yard} src={D8} alt="14 ft" />
                </div>
                <div className={styles.text}>40 Yard</div>
            </div>

        */}
        </div>
    </div>
  )
}

export default Tote



















































// import React, { useState } from 'react'
// import styles from './Tote.module.css'
// import container1 from "../../assets/container1.png";
// import container2 from "../../assets/container2.png";
// import container3 from "../../assets/container3.png";
// import ft12 from "../../assets/dimensions/12ft.png";
// import ft22 from "../../assets/dimensions/22ft.png";
// import ft4 from "../../assets/dimensions/4ft.png";
// import ft8 from "../../assets/dimensions/8ft.png";
// import Wings from '../Wings/Wings';

// const Tote = () => {

//     const [tote, setTote] = useState('14yard')
//     const [showWings, setShowWings] = useState(false)

//     const handleMouseAction = (toteName) => {
//         if (tote !== toteName) setTote(toteName);
//         if(tote !== toteName) {
//             setTimeout(() => {
//                 setShowWings(true)
//             }, 100)
//             setTimeout(() => {
//                 setShowWings(false)
//             }, 800)
//         }
//         else {
//             setShowWings(true)
//             setTimeout(() => {
//                 setShowWings(false)
//             }, 700)
//         }
//     }

//     const handleMouseLeave = () => {
//         setTote('14yard')
//         setShowWings(false)
//     }

//   return (
//     <div>
//         <div className={styles.totes}>
//             <div>
//                 <div className={styles.imageContainer} onMouseEnter={() => handleMouseAction('14yard')} onClick={() => handleMouseAction('14yard')} onMouseLeave={() => setShowWings(false)}>
//                     <img className={styles.image} src={container1} alt='14 Yard' />
//                     {tote === '14yard' && <Wings forComponent={'tote'} showWings={showWings} />}
//                     <img className={styles.verticalLine} src={ft4} alt="4 ft" />
//                     <img className={styles.horizontalLine_14yard} src={ft12} alt="12 ft" />
//                 </div>
//                 <div className={`${styles.text} ${styles.bold}`}>14 Yard</div>
//             </div>
//             <div>
//                 <div className={styles.imageContainer} onMouseEnter={() => handleMouseAction('20yard')} onClick={() => handleMouseAction('20yard')} onMouseLeave={handleMouseLeave}>
//                     <img className={styles.image} src={container2} alt='20 Yard' />
//                     {tote === '20yard' && <Wings forComponent={'tote'} showWings={showWings} />}
//                     <img className={styles.verticalLine} src={ft4} alt="4 ft" />
//                     <img className={styles.horizontalLine_20yard} src={ft22} alt="22 ft" />
//                 </div>
//                 <div className={styles.text}>20 Yard</div>
//             </div>
//             <div>
//                 <div className={styles.imageContainer} onMouseEnter={() => handleMouseAction('40yard')} onClick={() => handleMouseAction('40yard')} onMouseLeave={handleMouseLeave}>
//                     <img className={styles.image} src={container3} alt='40 Yard' />
//                     {tote === '40yard' && <Wings forComponent={'tote'} showWings={showWings} />}
//                     <img className={styles.verticalLine} src={ft8} alt="8 ft" />
//                     <img className={styles.horizontalLine_40yard} src={ft22} alt="22 ft" />
//                 </div>
//                 <div className={styles.text}>40 Yard</div>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default Tote