import React from 'react';
import mediumBanner from '../../assets/banner images/medium/Moving_truck.jpg'
import largeBanner from '../../assets/banner images/large/Moving_truck.jpg'
import mobileBanner from '../../assets/banner images/mobile banner/Moving_truck.webp'
import MainBanner from '../../components/Main Banner/MainBanner';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import HorizontalContact from '../../components/Horizontal Card/HorizontalContact';
import map from '../../assets/map.png';
import { Helmet } from 'react-helmet'

const Contact = () => {
  let head= 'Our wings signify trust, responsibility, efficiency & speed.'
  let hc_head8='ABOUT US';
  let hc_head9='CONTACT US';
  let hc_para8= `We are dedicated to responsible efficient, safe waste management. Our waste experts can tailor solutions for your commercial and residential needs today.`
  let hc_para9='Have a question, a business challenge or want to learn more about us?'

  return (
    <>
      <Helmet>
        <title>Dragonfly Environmental Logistics - Contact Us</title>
      </Helmet>
      <Header/>
      <MainBanner largeBanner={largeBanner} mediumBanner={mediumBanner} mobileBanner={mobileBanner} havePara={false} heading={head} additionalClass={true} />
      <HorizontalContact heading1={hc_head8} heading2={hc_head9} desc1={hc_para8} desc2={hc_para9} bigimg={map}/>
     <Footer/>
    </>
  )
}

export default Contact