import React from 'react'
import styles from './HorizontalCard.module.css'

const HorizontalCard = ({ isLeft, rightImageText, img, text, heading, ...rest }) => {

  return (

    <div
      {...rest} className={isLeft ? styles.leftContainer : styles.rightContainer}>
      {isLeft ? (
        <>
          <div className={styles.imgContainer}>
            <img src={img} alt="" />
          </div>
          <div className={styles.textContainer}>
            <h2>{heading}</h2>
            <p>{text}</p></div>
        </>
      ) : (
        <>
          <div className={styles.textContainer}>
            <h2>{heading}</h2>
            <p>{text}</p>
            {rightImageText && (
              <p className={styles.rightImageText}>
                {rightImageText.map((item, i) => {
                  const { text, bold } = item
                  return ((i === 0) ? 
                    <>
                      <span className={bold ? styles.bold : styles.pipeText}> {text} </span>
                    </>
                    :
                    <>
                      <span className={styles.pipe}> | </span>
                      <span className={bold ? styles.bold : styles.pipeText}> {text} </span>
                    </>
                  )
                })}

              </p>
            )}
          </div>

          <div className={styles.imgContainer}><img src={img} alt='' /></div>
        </>
      )}

    </div>
  )
}

export default HorizontalCard