import React from 'react'
import HorizontalCard from '../../components/Horizontal Card/HorizontalCard';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Carousel from '../../components/Carousel/Carousel';
import { Helmet } from 'react-helmet'
import truck1 from '../../assets/images with logo/image1.jpg'
import truck2 from '../../assets/images with logo/image3.jpg'
import truck3 from '../../assets/images with logo/image13.png'

const Home = () => {
  let hc_head='Commercial Services';
  let hc_head2='Residential Services';
  let hc_para= `Tailored programs to fit seamlessly with your business needs. Let us help unlock your growth potential.`
  let hc_para2=`Convenient, safe and affordable options with a wide variety of equipment.`

  return (
    <>
      <Helmet>
        <title>Dragonfly Environmental Logistics - Home</title>
      </Helmet>
      <Header/>
      <Carousel/>
      <HorizontalCard isLeft={true} heading={hc_head} text={hc_para} img={truck3}/>
      <HorizontalCard isLeft={false} heading={hc_head2} text={hc_para2} img={truck2}/>
      <Footer/>
    </>
  )
    
}

export default Home