import React from "react";
import styles from "./Footer.module.css";
import footer_logo from "../../assets/logo_footer.png";
import { useNavigate, Link } from "react-router-dom";

export default function Footer() {

  const navigate = useNavigate()

  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.logoContainer}>
          <img src={footer_logo} alt="footer-img" className={styles.logo} onClick={() => {
            navigate('/')
            window.scrollTo(0, 0)
          }}
          />
        </div>

        <div className={styles.textContainer}>
          <p className={styles.footerPara}>
            <span className={styles.bold}>Call now for a quote.</span><br />
            Call: <a className={styles.color} href="tel:888-335-9368">888-DFLY-ENV (888-335-9368)</a>
            <br />
            Email: <a className={styles.color} href="mailto:customerservice@dragonflyenvironmental.com">customerservice@dragonflyenvironmental.com</a>
          </p>
        </div>

      </div>
      <div className={styles.footerBottom}>
        <p>

          <Link to="/terms" onClick={() => window.scrollTo(0, 0)} >Terms of Use</Link>
          {' | '}
          <Link to="/privacy" onClick={() => window.scrollTo(0, 0)} >Privacy Policy</Link>
        </p>

        <p>&copy; {new Date().getFullYear()} Dragonfly Environmental Logistics</p>
      </div>
    </div>
  );
}
