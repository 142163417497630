
import React, { useEffect, useState } from 'react'
import styles from './Carousel.module.css'
import mediumImg1 from '../../assets/banner images/medium/dragonfly_home.webp'
import mediumImg2 from '../../assets/banner images/medium/lift_20058.webp'
import largeImg1 from '../../assets/banner images/large/dragonfly_home.webp'
import largeImg2 from '../../assets/banner images/large/lift_20058.webp'
import bannerStyles from '../Main Banner/MainBanner.module.css'
import MobileBanner from '../MobileBanner/MobileBanner'
import mobileBanner1 from '../../assets/banner images/mobile banner/dragonfly_home.webp'
import mobileBanner2 from '../../assets/banner images/mobile banner/lift_20058.webp'

export default function Carousel() {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const handleResize = (e) => {
    setScreenWidth(e.target.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const heading1 = 'Transformation, adaptability, renewal and responsibility.'
  const para1 = 'At Dragonfly Environmental Logistics we believe many small responsible actions can make a big beautiful impact.'
  
  const heading2 = 'Dragonfly Environmental Logistics has you covered.'
  const para2 = 'Swift, efficient, versatile, responsible waste management solutions for an evolving planet.'

  return (
    <>
      <div id="carouselSlide" class="carousel slide" data-ride="carousel" >{/* data-interval="3000" */}
        <ol  className={`carousel-indicators ${styles.customIndicators}`}>
          <li 
          data-target="#carouselSlide" 
          data-slide-to="0" class="active"></li>
          <li 
          data-target="#carouselSlide" 
          data-slide-to="1"></li>
        </ol>
        <div className={`carousel-inner ${styles.carouselItem}`}>
          <div class="carousel-item">
            {screenWidth > 600 ? 
            <div className={bannerStyles.mainBanner}>
              <img className={bannerStyles.image} src={screenWidth > 980 ? largeImg1 : mediumImg1} alt="banner" /> 
              <div className={bannerStyles.mainBannerContent}>
                <h1 className={bannerStyles.heading}>{heading1}</h1>
                <p className={bannerStyles.text}>{para1}</p>
              </div>
            </div>
            : 
            <MobileBanner height={screenWidth*3/4} banner={mobileBanner1} heading={heading1} para={para1} />}
          </div>
          <div class="carousel-item active">
            {screenWidth > 600 ? 
            <div className={`${bannerStyles.mainBanner} ${bannerStyles.mainBannerAdditional}`}>
              <img className={bannerStyles.image} src={screenWidth > 980 ? largeImg2 : mediumImg2} alt="banner" />
              <div className={bannerStyles.mainBannerContent1}>
                <h1 className={bannerStyles.heading}>{heading2}</h1>
                <p className={bannerStyles.text}>{para2}</p>
              </div>
            </div>
            : 
            <MobileBanner height={screenWidth*3/4} banner={mobileBanner2} heading={heading2} para={para2} additionalClass={true} page={'home-carousel2'} />}
          </div>
        </div>
        <a className={`carousel-control-prev ${styles.carouselControlPrev}`}href="#carouselSlide" role="button" data-slide="prev">
          <span className={`carousel-control-prev-icon`} aria-hidden="true"></span>
          <span class="sr-only"></span>
        </a>
        <a className={`carousel-control-next ${styles.carouselControlNext}`} href="#carouselSlide" role="button" data-slide="next">
          <span className={`carousel-control-next-icon `} aria-hidden="true"></span>
          <span class="sr-only"></span>
        </a>
      </div> 
    </>
  )
}
