import React, { useEffect, useState } from "react";
import styles from "./MainBanner.module.css";
import MobileBanner from "../MobileBanner/MobileBanner";


export default function MainBanner({ largeBanner, mediumBanner, mobileBanner, havePara, heading, para, additionalClass, page }) {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const handleResize = (e) => {
    setScreenWidth(e.target.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    screenWidth > 600 ?
    <>
      <div className={`${styles.mainBanner} ${additionalClass && styles.mainBannerAdditional}`}>
        <img className={styles.image} src={screenWidth > 980 ? largeBanner: mediumBanner} alt="banner" />
        <div className={additionalClass ? `${styles.mainBannerContent} ${styles.mainBannerContentAdditional}` : styles.mainBannerContent}>
          <h1 className={styles.heading}>{heading}</h1>
          {havePara && <p className={styles.text}>{para}</p>}
        </div>
      </div>
    </> :
    <MobileBanner page={page} height={screenWidth*3/4} banner={mobileBanner} heading={heading} para={para} additionalClass={additionalClass} />
  );
}
