import React from 'react'
import mediumBanner from '../../assets/banner images/medium/dragonfly_sustainability.webp';
import largeBanner from '../../assets/banner images/large/dragonfly_sustainability.webp';
import mobileBanner from '../../assets/banner images/mobile banner/dragonfly_sustainability.webp';
import MainBanner from '../../components/Main Banner/MainBanner';
import YellowBanner from '../../components/Yellow Banner/YellowBanner';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import HorizontalCard from '../../components/Horizontal Card/HorizontalCard';
import heartEarth from '../../assets/images with logo/image12.jpg'
import { Helmet } from 'react-helmet'

const Sustainability = () => {

  let mb_head = 'Responsible waste management goes down to the fine details';
  let mb_para = 'Our partnerships enable us to employ the most progressive technologies in Zero-Waste management.'
  let yb_head = 'Dragonfly is committed to providing a sustainable waste management approach to recycling and organic materials.';
  let yb_para = `Our commitment to sustainability is at the core of our company’s waste 
  management strategy. We strive to provide accessible, cost-effective and sustainable solutions to all our customers to reduce waste that would otherwise end up in a landfill.`;
  let yb_head2 = 'We have a collective responsibility to protect the planet. Every action we take in waste management matters.'
  let hc_head7 = 'OUR SUSTAINABLE APPROACH'
  let hc_para7 = `• Separation of Recyclables
  • Single Stream Collection Programs
  • Categorized Waste Collection and Diversion
  • Work Place Education
  • Waste Reduction Through Acquisition
  • Recycle, Reuse, Reduce, Recover
  • Zero Waste to landfill options`
  
  return (
    <>
      <Helmet>
        <title>Dragonfly Environmental Logistics - Sustainability</title>
      </Helmet>
      <Header/>
      <MainBanner largeBanner={largeBanner} mediumBanner={mediumBanner} mobileBanner={mobileBanner} havePara={true} heading={mb_head} para={mb_para} page={'sustainability'} />
      <YellowBanner havePara={true} heading={yb_head} para={yb_para}/>
      <HorizontalCard isLeft={false} heading={hc_head7} text={hc_para7.split('\n').map((point, index) => <p key={index}>{point}</p>)} img={heartEarth} />
      <YellowBanner havePara={false} heading={yb_head2}/>
      <Footer/>
    </>
  )
}

export default Sustainability