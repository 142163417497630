import React from "react";
import styles from "../Privacy/Privacy.module.css";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <div className={styles.terms_privacy}>
      <h1 className={styles.priv_heading}>TERMS OF USE </h1>
      <p className={styles.para}>
        to the Website of <i> DRAGONFLY ENVIROMENTAL LOGISTICS </i>(
        <Link to="/" style={{ color: "#221F20", textDecoration: "none" }}>
          dragonflyenvironmental.com
        </Link>
        ). Your use of our Website indicates your agreement with the following
        Terms of Use. If you do not agree to be bound by and comply with these
        Terms of Use, please do not access or use this Website.
      </p>

      <h3 className={styles.heading}>Intellectual property notice </h3>
      <p className={styles.para}>
        This <i>DRAGONFLY ENVIRONMENTAL LOGISTICS</i> Website, including any
        content appearing on or accessible through the site, is protected by
        copyright. Any adaptation, reproduction or distribution thereof is
        hereby strictly prohibited unless expressly authorized.
      </p>

      <h3 className={styles.heading}>Exclusion of Liability</h3>
      <p className={styles.para}>
        <i>DRAGONFLY ENVIRONMENTAL LOGISTICS</i> does not warrant the accuracy,
        relevance or reliability of this Website’s contents, nor does it
        guarantee that the contents will be available without errors or
        omissions. The information contained in this Website shall in no
        circumstances be construed as a legal opinion and{" "}
        <i>DRAGONFLY ENVIRONMENTAL LOGISTICS </i>is not responsible for any
        usage thereof.
      </p>

      <h3 className={styles.heading}>Privacy statement</h3>
      <p className={styles.para}>
        <i>DRAGONFLY ENVIRONMENTAL LOGISTICS </i>is committed to upholding your
        right to privacy in accordance with the laws of Québec and Canada
        respecting the protection of personal information.
      </p>
      <p className={styles.para}>
        <i>DRAGONFLY ENVIRONMENTAL LOGISTICS</i> may collect personal
        information about you. Personal data collection is for the exclusive use
        of the Firm, to allow us to communicate with you, provide you with the
        services and information you request or provide you with information
        that may be of interest to you. Unless otherwise authorized by you,{" "}
        <i> DRAGONFLY ENVIRONMENTAL LOGISTICS </i>will use personal information
        for these purposes only. If you do not wish to receive additional
        information, you may advise us accordingly.
      </p>
    </div>
  );
};

export default Terms;