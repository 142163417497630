import React from 'react'
import styles from './Services.module.css'

import img1 from '../../assets/services/waste1.png'
import img2 from '../../assets/services/recycling1.png'
import img3 from '../../assets/services/organics1.png'
import img4 from '../../assets/services/construction1.png'
import img5 from '../../assets/services/liquids1.png'
import img6 from '../../assets/services/restaurant1.png'
import img7 from '../../assets/services/metal1.png'
import img8 from '../../assets/services/certified1.png'

const services_res = [
    {image: img1, title: 'WASTE COLLECTION', text: ''},
    {image: img2, title: 'RECYCLING', text: ''},
    {image: img3, title: 'ORGANICS & COMPOST', text: ''},
    {image: img4, title: 'CONSTRUCTION MATERIALs', text: ''}
]
const services_comm = [
    {image: img1, title: 'GENERAL WASTE COLLECTION', text: ''},
    {image: img2, title: 'RECYCLING', text: ''},
    {image: img3, title: 'ORGANICS & COMPOST', text: ''},
    {image: img6, title: 'RESTAURANT, PRODUCE & BAKERY', text: '(Pre- & post-consumer)'}
]
const services_comm2 = [
    {image: img5, title: 'LIQUID WASTE SERVICES', text: ''},
    {image: img4, title: 'CONSTRUCTION WASTE SERVICES', text: ''},
    {image: img7, title: 'METAL RECYCLING/RECOVERY', text: ''},
    {image: img8, title: 'CERTIFIED DESTRUCTION', text: ''}
]

const Services = ({ page }) => {
  return (
    <div>
        {page === 'residential' ? 
        <div className={styles.tiles}>
            {services_res.map(({image, title, text}) => 
                <div key={title} className={styles.serviceContainer}>
                    <div>
                        <img className={styles.image} src={image} alt={title}/>
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.title}>{title}</div>
                        {text && <div className={styles.text}>{text}</div>}
                    </div>
                </div>
            )}
        </div>
        :
        <>
            <div className={styles.tiles}>
                {services_comm.map(({image, title, text}) => 
                    <div key={title} className={styles.serviceContainer}>
                        <div>
                            <img className={styles.image} src={image} alt={title}/>
                        </div>
                        <div className={styles.textContainer}>
                            <div className={styles.title}>{title}</div>
                            {text && <div className={styles.text}>{text}</div>}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.tiles}>
                {services_comm2.map(({image, title, text}) => 
                    <div key={title} className={styles.serviceContainer}>
                        <div>
                            <img className={styles.image} src={image} alt={title}/>
                        </div>
                        <div className={styles.textContainer}>
                            <div className={styles.title}>{title}</div>
                            {text && <div className={styles.text}>{text}</div>}
                        </div>
                    </div>
                )}
            </div>
        </>
        }
    </div>
  )
}

export default Services