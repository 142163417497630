import React from 'react';
import mediumBanner from '../../assets/banner images/medium/truck_fleet.webp';
import largeBanner from '../../assets/banner images/large/truck_fleet.webp';
import mobileBanner from '../../assets/banner images/mobile banner/truck_fleet.webp';
import MainBanner from '../../components/Main Banner/MainBanner';
import YellowBanner from '../../components/Yellow Banner/YellowBanner';
import Header from '../../components/Header/Header'
import HorizontalCard from '../../components/Horizontal Card/HorizontalCard'
import Footer from '../../components/Footer/Footer'
import truck3 from '../../assets/images with logo/image5.jpg'
import truck4 from '../../assets/images with logo/image2.jpg'
import { Helmet } from 'react-helmet'
import Bin from '../../components/Bin/Bin'
import Services from '../../components/Services/Services';
import Tote from '../../components/Tote/Tote';

const ResidentialServices = () => {
  let mb_head = 'Our residential solutions are responsible, cost-effective and customizable.';
  let mb_para = 'From general collection, to renovation services and any other surprises. We have you covered.';
  let yb_head= 'Waste management isn’t always predictable. In fact, it rarely is. We have standard equipment and solutions to service every residential need.';
  let yb_para = 'We also love a challenge, so call Dragonfly and throw us your curveball, our customization team will deliver a solution within 24 hours.';
  let hc_head3 = 'ROLL-OFF SERVICES'
  let hc_head4 = 'CURBSIDE SERVICES'
  let hc_para4 = 'Curbside pickup services available.'
  let hc_size3 = [{text: '14 Yard', bold: true}, {text: '20 Yard'}, {text: '40 Yard'}]
  let hc_size4 = [{text: '32 Gallon', bold: true}, {text: '96 Gallon'}]
  
  return (
    <>
      <Helmet>
        <title>Dragonfly Environmental Logistics - Residential Services</title>
      </Helmet>
      <Header/>
      <MainBanner largeBanner={largeBanner} mediumBanner={mediumBanner} mobileBanner={mobileBanner} havePara={true} heading={mb_head} para={mb_para} additionalClass={true} page={'residential'} />
      <Services page={'residential'} />
      <YellowBanner havePara={true} heading={yb_head} para={yb_para}/>
      <HorizontalCard isLeft={false} heading={hc_head3} rightImageText={hc_size3} img={truck3}/>
      <Tote/>
      <HorizontalCard isLeft={false} heading={hc_head4} text={hc_para4} rightImageText={hc_size4} img={truck4}/>
      <Bin/>
      <Footer/>
    </>
  )
}

export default ResidentialServices